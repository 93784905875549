import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import {
  ContentfulExtendedCopy,
  IndexPageFeaturedResourcesQuery,
  Maybe,
  Scalars
} from "../../graphql/types"
import { IndexPageKey } from "../types/page"
import { Resource, ResourceType } from "../types/resource"
import { REQUIRED_LIST_LENGTH } from "../components/featured-resources/featured-resources"
import { itemCount, readTime } from "../core/utils"
import {
  ContentfulScriptureReference,
  getTranslateableScripture,
  mapScriptureReference
} from "../features/scriptures/mappers/scripture-reference-mapper"
import { TranslateableString } from "../types/intl"

interface Teacher {
  firstName: string
  lastName: string
  shortName: string
  image: {
    fluid: {
      src: string
    }
  } | null
}

export interface FeaturedItem {
  typeForIcon?: ResourceType
  name: string
  slug: string
  datePublished?: string
  primaryScriptureReference?: {
    start?: {
      book: string
      chapter?: number
      verse?: number
    }
    end?: {
      book: string
      chapter?: number
      verse?: number
    }
  }
  extendedCopy?: Array<ContentfulExtendedCopy>
  bodyCopy?: {
    childMarkdownRemark: {
      timeToRead: number
    }
  }
  items?: {
    id: string
  }[]
  subtitle?: string
  image?: {
    fluid: {
      src: string
    }
  }
  richText?: {
    json: Maybe<Scalars["JSON"]>
  }
  teachers?: Teacher[]
}

export const mapItem = (item: FeaturedItem, skipTime?: boolean): Resource => {
  let time = 0
  let image = ""
  let teacher = ""
  let avatar = ""
  let datePublished = undefined
  const typeForIcon = item.typeForIcon || "article"

  if (item.datePublished) {
    datePublished = Date.parse(item.datePublished)
  }

  if (item.bodyCopy || item.extendedCopy) {
    time = item.extendedCopy
      ? item.extendedCopy.reduce((acc, cur) => {
          const time = cur?.bodyCopy?.childMarkdownRemark?.timeToRead || 0

          return time + acc
        }, 0)
      : item.bodyCopy?.childMarkdownRemark?.timeToRead || 0
  } else if (item.richText) {
    time = readTime(documentToHtmlString(item.richText.json))
  }

  if (item.image) {
    image = item.image.fluid.src
  }

  if (item.teachers && item.teachers.length) {
    const firstTeacher = item.teachers[0]

    teacher = `${firstTeacher.shortName}`

    if (firstTeacher.image) {
      avatar = firstTeacher.image.fluid.src
    }
  }

  let subtitle: TranslateableString = teacher || item.subtitle || ""

  if (item.items) {
    subtitle = itemCount(item.items)
  }

  if (
    item.primaryScriptureReference &&
    item.primaryScriptureReference.start?.book
  ) {
    subtitle = item.primaryScriptureReference
      ? getTranslateableScripture(
          mapScriptureReference(
            item.primaryScriptureReference as ContentfulScriptureReference
          )
        )
      : ""
  }

  return {
    title: item.name!,
    slug: item.slug!,
    datePublished,
    time: skipTime ? undefined : time,
    avatar,
    subtitle,
    image,
    typeForIcon
  }
}

export const useFeaturedResources = (key: IndexPageKey): Resource[] => {
  const data: IndexPageFeaturedResourcesQuery = useStaticQuery(graphql`
    query IndexPageFeaturedResources {
      allContentfulIndexPage {
        nodes {
          ...FeaturedResources
        }
      }
    }
  `)

  return useMemo(() => {
    const indexPage = data.allContentfulIndexPage.nodes.find(
      (node) => node.key === key
    )

    const resources = indexPage?.featuredResources

    if (!resources || resources.length !== REQUIRED_LIST_LENGTH) {
      return []
    }

    return resources.map((item) => mapItem(item as FeaturedItem))
  }, [data, key])
}
