import { useStaticQuery, graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import { IndexPageFullDataQuery } from "../../graphql/types"
import { mapMetadata } from "../core/mappers/map-metadata"
import { IndexPageHeader, IndexPageKey } from "../types/page"
import { PartialMetadata } from "../types/metadata"
import { REQUIRED_LIST_LENGTH } from "../components/featured-resources/featured-resources"
import { Resource } from "../types/resource"
import { FeaturedItem, mapItem } from "./use-featured-resources"

interface IndexPageFullData {
  header: IndexPageHeader
  metadata: PartialMetadata
  featuredResources: Resource[]
}

export const useIndexPageData = (key: IndexPageKey): IndexPageFullData => {
  const indexPageDataList: IndexPageFullDataQuery = useStaticQuery(graphql`
    query IndexPageFullData {
      allContentfulIndexPage {
        nodes {
          ...IndexPageData
        }
      }
    }
  `)

  const indexPage = indexPageDataList.allContentfulIndexPage.nodes.find(
    (node) => node.key === key
  )

  const header: IndexPageHeader = {
    title: indexPage?.title || "",
    subtitle: indexPage?.subtitle?.subtitle || "",
    image: indexPage?.headerImage?.fluid?.src || "",
    imageFluid: indexPage?.headerImage?.fluid as FluidObject
  }

  const metadata: PartialMetadata = mapMetadata(
    indexPage?.metadata,
    {
      title: indexPage?.title,
      description: indexPage?.subtitle?.subtitle
    },
    {
      title: indexPage?.pageTitle
    }
  )

  let featuredResources: Resource[] = []

  const resources = indexPage?.featuredResources

  if (resources && resources.length === REQUIRED_LIST_LENGTH) {
    featuredResources = resources.map((item) =>
      mapItem(item as FeaturedItem, key === "devotionals")
    )
  }

  return {
    header,
    metadata,
    featuredResources
  }
}
